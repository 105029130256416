import {
    createBrowserRouter,
    RouterProvider as ReactRouterRouterProvider,
} from 'react-router-dom';
import { DynamicFavouriteIndex } from './dashboard/DynamicFavouriteIndex';
import { Error } from './Error';

export function RouterProvider() {
    const router = createBrowserRouter([
        {
            path: 'login',
            async lazy() {
                const { LoginPage } = await import('./LoginPage');

                return { Component: LoginPage };
            },
        },
        {
            path: '/',
            errorElement: <Error />,
            async lazy() {
                const { Root } = await import('./Root');

                return { Component: Root };
            },
            handle: {
                crumb: {
                    label: 'smartlearn',
                },
            },
            children: [
                {
                    async lazy() {
                        const { Overview } = await import(
                            './dashboard/Overview'
                        );

                        return { Component: Overview };
                    },
                    children: [
                        {
                            index: true,
                            element: (
                                <DynamicFavouriteIndex
                                    favouriteRoute="/favourites"
                                    fallbackRoute="/events"
                                />
                            ),
                        },
                        {
                            path: 'events',
                            async lazy() {
                                const { AllEvents } = await import(
                                    './dashboard/AllEvents'
                                );

                                return { Component: AllEvents };
                            },
                        },
                        {
                            path: 'favourites',
                            async lazy() {
                                const { OverviewFavorites } = await import(
                                    './dashboard/OverviewFavourites'
                                );

                                return { Component: OverviewFavorites };
                            },
                        },
                    ],
                },
                {
                    path: 'exams',
                    async lazy() {
                        const { Exams } = await import('./dashboard/Exams');

                        return { Component: Exams };
                    },
                    children: [
                        {
                            index: true,
                            element: (
                                <DynamicFavouriteIndex
                                    favouriteRoute="/exams/favourites"
                                    fallbackRoute="/exams/events"
                                />
                            ),
                        },
                        {
                            path: 'events',
                            async lazy() {
                                const { Events } = await import(
                                    './dashboard/Events'
                                );

                                return { Component: Events };
                            },
                        },
                        {
                            path: 'variants',
                            async lazy() {
                                const { Variants } = await import(
                                    './dashboard/Variants'
                                );

                                return { Component: Variants };
                            },
                        },
                        {
                            path: 'pilots',
                            async lazy() {
                                const { Pilots } = await import(
                                    './dashboard/Pilots'
                                );

                                return { Component: Pilots };
                            },
                        },
                        {
                            path: 'favourites',
                            async lazy() {
                                const { Favorites } = await import(
                                    './dashboard/Favourites'
                                );

                                return { Component: Favorites };
                            },
                        },
                    ],
                },
                {
                    id: 'eventPage',
                    path: 'exams/:examId/events/:eventId',
                    async lazy() {
                        const { EventPage } = await import(
                            './events/EventPage'
                        );

                        return { Component: EventPage };
                    },
                    errorElement: <Error />,
                    children: [
                        {
                            id: 'eventCockpitPage',
                            index: true,
                            async lazy() {
                                const { EventCockpit } = await import(
                                    '~/components/cockpit/EventCockpit'
                                );

                                return { Component: EventCockpit };
                            },
                            errorElement: <Error />,
                        },
                        {
                            id: 'eventEnvironmentsPage',
                            path: 'environments',
                            errorElement: <Error />,
                            async lazy() {
                                const { EventEnvironmentsPage } = await import(
                                    './events/environments/EventEnvironmentsPage'
                                );

                                return { Component: EventEnvironmentsPage };
                            },
                        },
                        {
                            id: 'eventCorrectionPage',
                            path: 'correction',
                            errorElement: <Error />,
                            async lazy() {
                                const { EventCorrectionPage } = await import(
                                    './events/correction/EventCorrectionPage'
                                );

                                return { Component: EventCorrectionPage };
                            },
                            children: [
                                {
                                    id: 'eventCorrectionAnswersPage',
                                    path: 'answers',
                                    errorElement: <Error />,
                                    async lazy() {
                                        const {
                                            EventCorrectionAnswersPage,
                                        } = await import(
                                            './events/correction/EventCorrectionAnswersPage'
                                        );

                                        return {
                                            Component: EventCorrectionAnswersPage,
                                        };
                                    },
                                },
                                {
                                    id: 'eventCorrectionCandidatesPage',
                                    path: 'candidates',
                                    async lazy() {
                                        const {
                                            EventCorrectionCandidatesPageLoader,
                                        } = await import(
                                            '~/lib/router/loaders/EventCorrectionCandidatesPageLoader'
                                        );

                                        return {
                                            loader: EventCorrectionCandidatesPageLoader,
                                        };
                                    },
                                    children: [
                                        {
                                            index: true,
                                            errorElement: <Error />,
                                            async lazy() {
                                                const {
                                                    EventCorrectionCandidatesPage,
                                                } = await import(
                                                    './events/correction/EventCorrectionCandidatesPage'
                                                );

                                                return {
                                                    Component: EventCorrectionCandidatesPage,
                                                };
                                            },
                                        },
                                        {
                                            path: 'pass/:examPassId',
                                            errorElement: <Error />,
                                            async lazy() {
                                                const {
                                                    EventCorrectionCandidatesPage,
                                                } = await import(
                                                    './events/correction/EventCorrectionCandidatesPage'
                                                );

                                                return {
                                                    Component: EventCorrectionCandidatesPage,
                                                };
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'eventResultsPage',
                            path: 'results',
                            errorElement: <Error />,
                            async lazy() {
                                const { EventResultsPage } = await import(
                                    './events/EventResultsPage'
                                );

                                return { Component: EventResultsPage };
                            },
                        },
                    ],
                },
                {
                    path: 'author/exam',
                    async lazy() {
                        const { Author } = await import('./Author');

                        return { Component: Author };
                    },
                },
                // TODO: Remove longterm. Route is now handled in path of 'exams/events'. Keep midterm for redirects of legacy urls.
                {
                    id: 'legacyCockpitPage',
                    path: 'cockpit',
                    async lazy() {
                        const { LegacyCockpitPage } = await import(
                            './events/LegacyCockpitPage'
                        );

                        return { Component: LegacyCockpitPage };
                    },
                },
                {
                    path: 'log',
                    async lazy() {
                        const { Log } = await import('./Log');

                        return { Component: Log };
                    },
                },
                {
                    path: 'exam',
                    async lazy() {
                        const { ExamPassPage } = await import('./ExamPassPage');

                        return { Component: ExamPassPage };
                    },
                },
                {
                    path: 'correction',
                    async lazy() {
                        const { LegacyCorrectionPage } = await import(
                            './events/correction/LegacyCorrectionPage'
                        );

                        return { Component: LegacyCorrectionPage };
                    },
                },
                {
                    path: 'results',
                    async lazy() {
                        const { LegacyResultsPage } = await import(
                            './events/LegacyResultsPage'
                        );

                        return { Component: LegacyResultsPage };
                    },
                },
                {
                    path: 'export',
                    async lazy() {
                        const { Export } = await import('./Export');

                        return { Component: Export };
                    },
                },
            ],
        },
    ]);

    return <ReactRouterRouterProvider router={router} />;
}
