import { Result, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export type LoadingNotFoundType = {
    isLoading?: boolean;
    loadingTitle?: string;
    errorTitle: string;
};

export const LoadingNotFound = (props: LoadingNotFoundType) => {
    const { isLoading, loadingTitle, errorTitle } = props;
    return isLoading ? (
        <div style={{ paddingTop: 100, textAlign: 'center' }}>
            <Spin
                delay={500}
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
                tip={loadingTitle}
            />
        </div>
    ) : (
        <Result status="404" title={errorTitle} />
    );
};
