import { Button, Result } from 'antd';
import { ReactNode } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type ErrorState = { hasError: boolean; errorEventId?: string };
type ErrorProps = { children: ReactNode };

class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props);
        this.state = { hasError: false, errorEventId: undefined };
    }

    static getDerivedStateFromError(): ErrorState {
        return { hasError: true };
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <Result
                    style={{ backgroundColor: 'white' }}
                    status="500"
                    title={<FormattedMessage id={'errorboundary.titel'} />}
                    subTitle={
                        <FormattedMessage id={'errorboundary.subtitel'} />
                    }
                    extra={[
                        <Button
                            key="reload-section"
                            onClick={() => this.setState({ hasError: false })}
                        >
                            <FormattedMessage
                                id={'errorboundary.reload.part'}
                            />
                        </Button>,
                        <Button
                            key="reload-page"
                            type="primary"
                            onClick={() => window.location.reload()}
                        >
                            <FormattedMessage
                                id={'errorboundary.reload.page'}
                            />
                        </Button>,
                    ]}
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
