import type { MessageDescriptor } from 'react-intl';
import { useIntl } from 'react-intl';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { CombinedError } from 'urql';
import { LoadingNotFound } from '~/components/shared/LoadingNotFound';

export interface ErrorProps<T = unknown> {
    title: MessageDescriptor;
    errorMeta: T;
}

export function Error() {
    const error = useRouteError();
    const intl = useIntl();

    if (isRouteErrorResponse(error) && error.status === 404) {
        return (
            <>
                <h1>404 Page Not Found</h1>
                This page could not be found.
            </>
        );
    } else if (isCombinedError(error)) {
        if (
            error.errorMeta.graphQLErrors.some((graphqlError) =>
                [403, 404].includes(graphqlError.extensions.status as number)
            )
        ) {
            return (
                <LoadingNotFound errorTitle={intl.formatMessage(error.title)} />
            );
        }
    } else {
        return (
            <>
                <h1>500 Internal Server Error</h1>
                Something went wrong.
            </>
        );
    }
}

function isCombinedError(error: any): error is ErrorProps<CombinedError> {
    return (
        (error as ErrorProps<CombinedError>)?.errorMeta?.graphQLErrors !==
        undefined
    );
}
