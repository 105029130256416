import { action, Action } from 'easy-peasy';
import { SmlSelectItem } from '~/components/ui/Select/Select';

const getCourse = () => {
    try {
        const dashboardFilter = window.localStorage.getItem(
            'smartlearn.dashboardFilter'
        );
        return JSON.parse(dashboardFilter).course ?? null;
    } catch (e) {
        return null;
    }
};

const getCreatedBy = () => {
    try {
        const dashboardFilter = window.localStorage.getItem(
            'smartlearn.dashboardFilter'
        );
        return JSON.parse(dashboardFilter).createdBy ?? '';
    } catch (e) {
        return '';
    }
};

const getSearchName = () => {
    try {
        const dashboardFilter = window.localStorage.getItem(
            'smartlearn.dashboardFilter'
        );
        return JSON.parse(dashboardFilter).searchName ?? '';
    } catch (e) {
        return '';
    }
};

const saveFilter = (item: { [key: string]: any }) => {
    try {
        const dashboardFilter = window.localStorage.getItem(
            'smartlearn.dashboardFilter'
        );
        const json = JSON.parse(dashboardFilter);
        window.localStorage.setItem(
            'smartlearn.dashboardFilter',
            JSON.stringify({ ...json, ...item })
        );
    } catch (e) {
        window.localStorage.setItem(
            'smartlearn.dashboardFilter',
            JSON.stringify(item)
        );
    }
};

type FilterCount = {
    pilotCount: number;
    variantCount: number;
    eventsCount: number;
    passesCount: number;
    favouriteCount: number;
};

export interface DashboardFilterModel {
    course: SmlSelectItem;
    createdBy: string;
    searchName: string;
    changeCourse: Action<DashboardFilterModel, SmlSelectItem>;
    changeCreatedBy: Action<DashboardFilterModel, string>;
    changeSearchName: Action<DashboardFilterModel, string>;
    filterCount: FilterCount;
    updateFilter: Action<DashboardFilterModel, FilterCount>;
}

const dashboardFilterModel: DashboardFilterModel = {
    course: getCourse(),
    createdBy: getCreatedBy(),
    searchName: getSearchName(),
    changeCourse: action((state, payload) => {
        saveFilter({ course: payload });
        state.course = payload;
    }),
    changeCreatedBy: action((state, payload) => {
        saveFilter({ createdBy: payload });
        state.createdBy = payload;
    }),
    changeSearchName: action((state, payload) => {
        saveFilter({ searchName: payload });
        state.searchName = payload;
    }),
    filterCount: {
        pilotCount: 0,
        variantCount: 0,
        eventsCount: 0,
        passesCount: 0,
        favouriteCount: 0,
    },
    updateFilter: action((state, payload) => {
        state.filterCount = payload;
    }),
};

export default dashboardFilterModel;
